













































































































































































































































import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
} from 'date-fns';
import {cloneDeep} from 'lodash';
import Vue from 'vue';
import {
  mdiCalendar,
  mdiCash,
  mdiCashRemove,
  mdiCurrencyUsdOff,
  mdiDotsVertical,
  mdiDownload,
  mdiMagnify,
  mdiPencil,
} from '@mdi/js';
import DownloadCsv from 'vue-json-csv';
import Component from 'vue-class-component';
import PageTitle from '@/components/PageTitle.vue';
import {minutesToHours} from '@/filters';
import {ProjectResource, SelectItem, TimeEntryAttributes} from '@/interfaces';
import {BillingStatus, Role} from '@/enums';
import {
  authModule,
  projectModule,
  taskModule,
  clientModule,
  timeEntryModule,
} from '@/store/modules';
import {numberFormat} from '@/filters';
import {VueShowdown} from 'vue-showdown/dist/vue-showdown.esm';

@Component({
  components: {DownloadCsv, PageTitle, VueShowdown},
})
export default class TimeEntries extends Vue {
  billingStatuses: SelectItem[] = [
    {text: 'All', value: -1},
    {text: 'Not Invoiced', value: BillingStatus.NotInvoiced},
    {text: 'Invoiced', value: BillingStatus.Invoiced},
    {text: 'Not billable', value: BillingStatus.NotBillable},
  ];
  users: SelectItem[] = [
    {text: 'All', value: -1},
    {text: 'John', value: 1},
    {text: 'James', value: 6},
    {text: 'Eli', value: 7},
  ];
  csvFieldsClient: string[] = [
    'date',
    'project',
    'task',
    'hours',
    'description',
    'user',
  ];
  csvFieldsStaff: string[] = [
    'date',
    'client',
    'project',
    'task',
    'hours',
    'description',
    'user',
  ];
  dateEndMenu = false;
  dateStartMenu = false;
  private expanded: string[] = [];
  groupByStaff: SelectItem[] = [
    {text: 'None', value: null},
    {text: 'Project', value: 'project.name'},
    {text: 'Task', value: 'task.name'},
    {text: 'Client', value: 'client.name'},
  ];
  groupByClient: SelectItem[] = [
    {text: 'None', value: null},
    {text: 'Project', value: 'project.name'},
    {text: 'Task', value: 'task.name'},
  ];
  headersClient = [
    //date,client, project, hours, task, desc, billed, actions
    {
      align: 'center',
      text: 'Date',
      value: 'date',
    },
    {text: 'Project', value: 'project.name'},
    {
      text: 'Hours',
      value: 'duration',
    },
    {text: 'Task', value: 'task.name'},
    // {text: 'Description', value: 'description'},
    {text: 'Invoiced', value: 'billed'},
    {text: '', value: 'data-table-expand'},
  ];
  headersStaff = [
    //date,client, project, hours, task, desc, bille, actions
    {
      align: 'center',
      text: 'Date',
      value: 'date',
    },
    {
      text: 'Client',
      align: 'left',
      name: 'client',
      value: 'client.name',
    },
    {text: 'Project', value: 'project.name'},
    {
      text: 'Hours',
      value: 'duration',
    },
    {text: 'Task', value: 'task.name'},
    {text: 'User', value: 'user.lastName'},
    // {text: 'Description', value: 'description'},
    {text: 'Invoiced', value: 'billed'},
    {align: 'center', text: 'Actions', value: 'action'},
    {text: '', value: 'data-table-expand'},
  ];
  iconCalendar = mdiCalendar;
  iconDownload = mdiDownload;
  private iconEdit = mdiPencil;
  iconSearch = mdiMagnify;
  iconInvoiced = mdiCash;
  iconNotInvoiced = mdiCashRemove;
  iconNotBillable = mdiCurrencyUsdOff;
  iconActions = mdiDotsVertical;
  quickDates: SelectItem[] = [
    {text: 'Today', value: 'today'},
    {text: 'This Week', value: 'this-week'},
    {text: 'Last Week', value: 'last-week'},
    {text: 'This Month', value: 'this-month'},
    {text: 'Last Month', value: 'last-month'},
    {text: 'This Year', value: 'this-year'},
  ];
  minutesToHours = minutesToHours;
  selected = [];
  private totalCols = 8;
  private roles = Role;

  get filters(): Record<string, string | number | null> {
    return timeEntryModule.getFilters;
  }

  get role(): Role | null {
    return authModule.role;
  }
  get clientId(): number | null {
    return authModule.clientId;
  }

  get loading(): boolean {
    return timeEntryModule.loading;
  }

  get clientsLoading(): boolean {
    return clientModule.loading;
  }

  get projectsLoading(): boolean {
    return projectModule.loading;
  }

  get tasksLoading(): boolean {
    return taskModule.loading;
  }

  get clientsCombobox(): SelectItem[] {
    return [
      ...[{text: 'All', value: -1}],
      ...clientModule.list.map(item => {
        return {
          text: item.attributes.name,
          value: item.id,
        };
      }),
    ];
  }

  get projectsCombobox(): SelectItem[] {
    return [
      ...[{text: 'All', value: -1}],
      ...this.projects.map(item => {
        return {
          text: item.attributes.name,
          value: item.id,
        };
      }),
    ];
  }

  get tasksCombobox(): SelectItem[] {
    return [
      ...[{text: 'All', value: -1}],
      ...taskModule.list.map(item => {
        return {
          text: item.attributes.name,
          value: item.id,
        };
      }),
    ];
  }

  get timeEntries(): TimeEntryAttributes[] {
    return timeEntryModule.list.map(item => {
      return item.attributes;
    });
  }

  get projects(): ProjectResource[] {
    if (this.filters.clientId === -1) {
      return projectModule.list;
    } else {
      return projectModule.list.filter(
        project =>
          String(project.attributes.clientId) ===
            String(this.filters.clientId) ||
          String(this.filters.clientId) === '-1'
      );
    }
  }

  get timeEntriesCsv(): Record<string, string>[] {
    const timeEntries = cloneDeep(this.timeEntries);

    return timeEntries.map((item: TimeEntryAttributes) => {
      const row: Record<string, string> = {};

      row.date = String(item.date);
      row.description = ' ' + item.description; // space to prevent #NAME? in excel
      row.hours = numberFormat(item.duration / 60);
      row.project = item.project.name;
      row.client = item.client.name;
      row.task = item.task.name;
      row.user = `${item.user.firstName} ${item.user.lastName}`;
      return row;
    });
  }

  async created(): Promise<void> {
    if (this.role === Role.Client) {
      this.filters.clientId = this.clientId;
      console.log(this.filters.groupBy);
      this.filters.groupBy =
        this.filters.groupBy === 'client.name' ? null : this.filters.groupBy;
    }
    if (this.role === Role.Staff) {
      await clientModule.loadMulti();
    }
    await projectModule.loadMulti({
      filters: {clientId: String(this.filters.clientId)},
    });
    await taskModule.loadMulti();
    if (!this.filters.dateStart || !this.filters.dateEnd) {
      await this.setDates('this-month');
    } else {
      await this.update();
    }

    // not needed, called in setDate
    //await this.update();
  }

  async update(): Promise<void> {
    timeEntryModule.setFilters();
    await timeEntryModule.loadMulti({
      filters: {
        billingStatus: String(this.filters.billingStatus),
        clientId: String(this.filters.clientId),
        dateStart: String(this.filters.dateStart),
        dateEnd: String(this.filters.dateEnd),
        groupBy: String(this.filters.groupBy),
        projectId: String(this.filters.projectId),
        taskId: String(this.filters.taskId),
        userId: String(this.filters.userId),
      },
    });
  }

  sumDurations(): number {
    return this.timeEntries.reduce((a, b) => a + (b.duration || 0), 0);
  }

  async setDates(quickDate: string): Promise<void> {
    const today = new Date();
    const lastWeek = subWeeks(today, 1);
    const lastMonth = subMonths(today, 1);
    switch (quickDate) {
      case 'today':
        this.filters.dateStart = format(today, 'yyyy-MM-dd');
        this.filters.dateEnd = format(today, 'yyyy-MM-dd');
        break;
      case 'this-week':
        this.filters.dateStart = format(startOfWeek(today), 'yyyy-MM-dd');
        this.filters.dateEnd = format(endOfWeek(today), 'yyyy-MM-dd');
        break;
      case 'last-week':
        this.filters.dateStart = format(startOfWeek(lastWeek), 'yyyy-MM-dd');
        this.filters.dateEnd = format(endOfWeek(lastWeek), 'yyyy-MM-dd');
        break;
      case 'this-month':
        this.filters.dateStart = format(startOfMonth(today), 'yyyy-MM-dd');
        this.filters.dateEnd = format(endOfMonth(today), 'yyyy-MM-dd');
        break;
      case 'last-month':
        this.filters.dateStart = format(startOfMonth(lastMonth), 'yyyy-MM-dd');
        this.filters.dateEnd = format(endOfMonth(lastMonth), 'yyyy-MM-dd');
        break;
      case 'this-year':
        this.filters.dateStart = format(startOfYear(today), 'yyyy-MM-dd');
        this.filters.dateEnd = format(endOfMonth(today), 'yyyy-MM-dd');
        this.filters.dateEnd = 1;
        break;
      default:
        console.error('No date specified');
    }
    await this.update();
  }
}
